/* Three divisions */

#headerbar {
  /* padding: 0.5%; */
  /* position: sticky;
    top: 1%;131a22
    z-index: 1; */
  /* margin: 2.5%; */
  /* border: 1px solid #934BED; */
  background: #fff;
  /* position: sticky; */
  top: 0;
  z-index: 100000000;
  box-shadow: 0 1.4px 1.1px rgba(54, 54, 54, 0.007),
    0 3.3px 2.6px rgba(56, 56, 56, 0.027), 0 6px 5px rgba(46, 46, 46, 0.027),
    0 11px 9px rgba(41, 41, 41, 0.034), 0 20.8px 15.4px rgba(39, 39, 39, 0.041),
    0 12px 10px rgba(0, 0, 0, 0);
  padding: 0.55%;
}

#appheaderbar {
  /* padding: 0.5%; */
  /* position: sticky;
    top: 1%;131a22
    z-index: 1; */
  /* margin: 2.5%; */
  /* border: 1px solid #934BED; */
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 100000000;
  /* border-bottom: 1px solid #e5e5e5; */
}

#blackheader {
  position: sticky;
  top: 0;
  z-index: 100000000000;
  background-color: black;
  padding-top: 1%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  border-bottom-left-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
  margin-bottom: 2%;
}

#desktopheader {
  display: block;
}

#mobileheader {
  display: none;
}

@media only screen and (max-width: 767px) {
  #desktopheader {
    display: none;
  }
  #mobileheader {
    display: block;
  }
  #mobileheaderlogo {
    content: url("https://storage.piksl.io/3.images/logo60pxwhite.png");
    width: 15%;
    opacity: 0.9;
  }
  #mobileheader {
    padding-top: 3.5%;
    padding-bottom: 3.5%;
    padding-right: 4%;
    padding-left: 4%;
  }
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar3 {
  width: 17px;
  height: 2px;
  background-color: #c0c0c0;
  margin: 3px 0;
  transition: 0.4s;
}

.invis {
  width: 17px;
  height: 2px;
  margin: 3px 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 2px);
  transform: rotate(-45deg) translate(-5px, 2px);
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -2px);
  transform: rotate(45deg) translate(-5px, -2px);
}

#menu-bar {
  font-size: 20%;
  cursor: pointer;
  display: none;
}

.menu-logo {
  float: left;
}

nav {
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin-top: 15%;
}

#navbar {
  display: none;
}

nav ul {
  float: left;
  line-height: 3;
}

nav li {
  display: inline;
  list-style-type: none;
}

nav a {
  /* font-size: 250%; */
}

@media only screen and (max-width: 768px) {
  #menu-bar {
    display: block;
    /* set this margin because its close to the window screen*/
  }
  .menu-logo {
    width: 100%;
  }
  nav {
  }
  nav ul {
    float: none;
    display: none;
    /* set display none for use the click fucntion to show the menu*/
    /* Set margin and padding 0 for full width border bottom*/
    margin: 0;
    padding: 0;
  }
  nav li {
  }
  nav a {
    display: block;
    color: #fff;
    margin: 0;
  }
}

.menuitem {
  font-size: 90%;
  padding-top: 7.5%;
  padding-bottom: 7.5%;
  font-weight: bold;
}
