.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 12.5px;
  font-size: 70%;
  text-align: center;
  box-shadow: 0 1.4px 1.1px rgba(0, 0, 0, 0.034),
    0 3.3px 2.6px rgba(0, 0, 0, 0.048), 0 6px 5px rgba(0, 0, 0, 0.06),
    0 11px 9px rgba(0, 0, 0, 0.072), 0 20.8px 15.4px rgba(0, 0, 0, 0.086),
    0 12px 10px rgba(0, 0, 0, 0);
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content a {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
}
.dropdown-content a:hover {
  color: #ffffff;
  background-color: #7b3fe4;
}
