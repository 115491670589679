.marquee {
  width: 100%;
  line-height: 50px;
  background-color: #7b3fe4;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  /* padding-left: 100%;
  animation: marquee 30s linear infinite; */
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
