.tabs {
  width: 33%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 12.5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin: 1%;
  padding-bottom: 1%;
}

.tabs:hover {
  margin-top: -1%;
  padding-bottom: 3%;
}
