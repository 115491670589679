.docs-heading {
  color: #aa7ef7;
  font-size: 100%;
}

.docs-subheading {
  color: #111111;
  margin-top: -1%;
  font-size: 250%;
  font-weight: bold;
  margin-bottom: 2%;
  text-transform: uppercase;
}

.docs-paragraph {
  color: #313131;
  font-size: 100%;
  /* font-family: "Roboto"; */
}

#docscontent {
  /* font-family: "Roboto"; */
}

.docs-page-buttons {
  width: 50%;
  border: 1px solid #e5e5e5;
  padding: 2%;
  cursor: pointer;
  border-radius: 12.5px;
  margin-top: 10%;
}

.docs-page-buttons:hover {
  border: 1px solid #7b3fe4;
}

.docs-page-buttons-sub {
  font-size: 80%;
  color: #606060;
}

.docs-page-buttons-main {
  font-size: 80%;
  color: #7b3fe4;
  font-weight: bold;
  display: flex;
}

.next {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  margin-left: 1%;
}

.previous {
  margin-right: 1%;
}

ul,
ol {
  font-size: 90%;
  font-family: "Roboto";
  color: #313131;
}

.docs-img {
  border: 1px solid #e5e5e5;
  border-radius: 12.5px;
  width: 75%;
  padding-top: 2%;
  padding-left: 2%;
}

#docs-headerbar {
  background: #fff;
  z-index: 1000;
}
#docs-sidebar {
  position: sticky;
  align-self: flex-start;
}
#docs-sidebarlinks {
  margin-left: 15%;
  margin-right: 15%;
}

.docs-sidebaricons {
  cursor: pointer;
  margin-left: 4%;
  /* color: #7b3fe4 !important; */

  /* font-weight: bold; */
}
.docs-sidebar-heading {
  font-weight: bold;
  color: black !important;
  font-size: 110%;
}

.docs-sidebar-a {
  color: #313131 !important;
}

.docs-sidebar-a-selected {
  color: #7b3fe4 !important;
}

.docs-sidebar-a:hover {
  color: #7b3fe4 !important;
}

.docs-sidebaricons:hover {
  color: #7b3fe4 !important;
}

.docs-sidebariconsdiv {
  margin-top: 5%;
  margin-bottom: 15%;
  /* margin-right: 10%; */
  /* padding: 5%; */
  border-radius: 25px;
}

.docs-iconselected {
  background: #ede6fa;
}

/* Mobile */

.sidenav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.4s;
  margin-left: -100%;
  /* padding-top: 10%; */
}

.sidenav a {
  text-decoration: none;
  font-size: 120%;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  /* position: absolute; */
  /* top: 2%;
  right: 10%; */
  font-size: 36px;
  /* margin-left: 50px; */
}

@media screen and (max-width: 767px) {
  .sidenav {
  }
  .sidenav a {
  }

  .docs-sidebar-a {
    color: #313131 !important;
    margin-bottom: -7.5%;
  }
  .docs-sidebar-heading {
    margin-bottom: -7.5%;
  }

  #docs-sidebarlinks {
    margin-left: 10%;
    margin-top: 10%;
  }

  .docs-paragraph {
    font-size: 120%;
  }
  .docs-heading {
    font-size: 120%;
  }
  ul {
    font-size: 120%;
  }
  .docs-img {
    width: 100%;
    font-size: 120%;
  }
}
