a:link {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
  text-size-adjust: 100%;
}

.bluelink {
  color: #7b3fe4 !important;
  cursor: pointer;
}

.bluelink:hover {
  color: #aa7ef7 !important;
}

.buttonlink:hover {
  background-color: transparent !important;
}

.bluebutton {
  border-radius: 50px;
  padding: 17.5px;
  background: #7b3fe4 !important;
  color: white;
  font-weight: bold;
  font-size: 80%;
}

.bluebutton:hover {
  background: #8b53eb !important;
}

.bluebuttonhollow {
  border-radius: 50px;
  padding: 17.5px;
  background: transparent !important;
  border: 2px solid #7b3fe4;
  color: #7b3fe4;
  font-weight: bold;
}

.bluebuttonhollow:hover {
  border: 2px solid #7b3fe4;
  color: #7b3fe4 !important;
  background: transparent !important;
}

.whitebutton {
  border-radius: 50px;
  padding: 12.5px;
  background: white;
  border: 2px solid white;
  color: #7b3fe4;
  font-weight: bold;
  font-size: 80%;
}

.whitebutton:hover {
  border: 2px solid #fff !important;
  background: white !important;
}

.whitebuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 70%;
}

.whitebuttonhollow:hover {
  background: transparent !important;
}
.blackbuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent !important;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  font-size: 70%;
}

.blackbuttonhollow:hover {
  color: black !important;
}

.whitebuttonhollow:hover {
  border: 2px solid #e5e5e5;
  color: #e5e5e5;
  background: transparent !important;
}

.headingbutton {
  border-radius: 50px;
  border: 1px solid #808080;
  font-size: 80%;
  padding: 7px;
  color: #808080;
  margin-bottom: 1%;
  cursor: none;
  background-color: #1b1b1b;
  font-weight: bold;
}

.headerbutton {
  border-radius: 50px;
  padding: 12.5px;
  background: transparent;
  color: black;
}

.headerbutton:hover {
  border: 2px solid #f4b962;
  background: #f4b962;
  color: #111111;
}

.mobileheadingbutton {
  border-radius: 50px;
  border: 1px solid #f4b962;
  font-size: 100%;
  padding: 7px;
  color: #f4b962;
  margin-bottom: 1%;
  cursor: none;
  background-color: #111111;
  font-weight: bold;
}
